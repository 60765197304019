export default function getFields($t) {
  return [
    {
      name: 'name',
      type: 'portal',
      infoHeader: true,
      fixedColumnHeader: true,
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_SalesItemName')
      },
      get title() {
        return $t('IncomeStats.tableColumn_SalesItemName')
      }
    },
    {
      name: 'totalSalesQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_TotalSalesQuantity')
      },
      get title() {
        return $t('IncomeStats.tableColumn_TotalSalesQuantity')
      }
    },
    {
      name: 'salesItemGrossSalesPrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('IncomeStats.tableColumn_SalesItemGrossSalesPrice')
      },
      get title() {
        return $t('IncomeStats.tableColumn_SalesItemGrossSalesPrice')
      }
    },
    {
      name: 'salesItemTaxRatio',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('IncomeStats.tableColumn_SalesItemTaxRatio')
      },
      get title() {
        return $t('IncomeStats.tableColumn_SalesItemTaxRatio')
      }
    },
    {
      name: 'salesItemNetSalesPrice',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('IncomeStats.tableColumn_SalesItemNetSalesPrice')
      },
      get title() {
        return $t('IncomeStats.tableColumn_SalesItemNetSalesPrice')
      }
    },
    {
      name: 'discountAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_discountAmount')
      },
      get title() {
        return $t('IncomeStats.tableColumn_discountAmount')
      }
    },
    {
      name: 'totalGrossSalesAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('IncomeStats.tableColumn_TotalGrossSalesAmount')
      },
      get title() {
        return $t('IncomeStats.tableColumn_TotalGrossSalesAmount')
      }
    },
    {
      name: 'totalNetSalesAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_TotalNetSalesAmount')
      },
      get title() {
        return $t('IncomeStats.tableColumn_TotalNetSalesAmount')
      }
    },
    {
      name: 'totalDiscountedSalesAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('IncomeStats.tableColumn_TotalDiscountedSalesAmount')
      },
      get title() {
        return $t('IncomeStats.tableColumn_TotalDiscountedSalesAmount')
      }
    },
    {
      name: 'unitCost',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_UnitCost')
      },
      get title() {
        return $t('IncomeStats.tableColumn_UnitCost')
      }
    },
    {
      name: 'totalCost',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_TotalCost')
      },
      get title() {
        return $t('IncomeStats.tableColumn_TotalCost')
      }
    },
    {
      name: 'unitProfit',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_UnitProfit')
      },
      get title() {
        return $t('IncomeStats.tableColumn_UnitProfit')
      }
    },
    {
      name: 'totalProfit',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_TotalProfit')
      },
      get title() {
        return $t('IncomeStats.tableColumn_TotalProfit')
      }
    },
    {
      name: 'costPercentage',
      type: 'percent',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_CostPercentage')
      },
      get title() {
        return $t('IncomeStats.tableColumn_CostPercentage')
      }
    },
    {
      name: 'profitPercentage',
      type: 'percent',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_ProfitPercentage')
      },
      get title() {
        return $t('IncomeStats.tableColumn_ProfitPercentage')
      }
    }
  ]
}
