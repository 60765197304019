export default function getFields($t) {
  return {
    1: [
      {
        name: 'groupName',
        is_visible: true,
        style: 'width: 15%',
        get short_title() {
          return $t('SalesReports.tableColumn_OverGroup_Short')
        },
        get title() {
          return $t('SalesReports.tableColumn_OverGroup')
        }
      },
      {
        name: 'totalCost',
        type: 'currency',
        is_visible: true,
        style: 'width: 15%',
        get short_title() {
          return $t('SalesReports.tableColumn_Cost_Short')
        },
        get title() {
          return $t('SalesReports.tableColumn_Cost')
        }
      },
      {
        name: 'spacer',
        type: 'spacer',
        is_visible: true
      }
    ],

    2: [
      {
        name: 'groupName',
        is_visible: true,
        style: 'width: 15%',
        get short_title() {
          return $t('SalesReports.tableColumn_MajorGroup_Short')
        },
        get title() {
          return $t('SalesReports.tableColumn_MajorGroup')
        }
      },
      {
        name: 'totalCost',
        type: 'currency',
        style: 'width: 15%',
        is_visible: true,
        get short_title() {
          return $t('SalesReports.tableColumn_Cost_Short')
        },
        get title() {
          return $t('SalesReports.tableColumn_Cost')
        }
      },
      {
        name: 'spacer',
        type: 'spacer',
        is_visible: true
      }
    ],

    3: [
      {
        name: 'groupName',
        is_visible: true,
        style: 'width: 15%',
        get short_title() {
          return $t('SalesReports.tableColumn_ItemGroup_Short')
        },
        get title() {
          return $t('SalesReports.tableColumn_ItemGroup')
        }
      },
      {
        name: 'totalCost',
        type: 'currency',
        is_visible: true,
        style: 'width: 15%',
        get short_title() {
          return $t('SalesReports.tableColumn_Cost_Short')
        },
        get title() {
          return $t('SalesReports.tableColumn_Cost')
        }
      },
      {
        name: 'spacer',
        type: 'spacer',
        is_visible: true
      }
    ]
  }
}
