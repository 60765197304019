export default function getFields($t) {
  return [
    {
      name: 'menuItemName',
      fixedColumnHeader: true,
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_ProductName')
      },
      get title() {
        return $t('IncomeStats.tableColumn_ProductName')
      }
    },
    {
      name: 'forecastQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_ForecastQuantity')
      },
      get title() {
        return $t('IncomeStats.tableColumn_ForecastQuantity')
      }
    },
    {
      name: 'forecastAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('IncomeStats.tableColumn_ForecastAmount')
      },
      get title() {
        return $t('IncomeStats.tableColumn_ForecastAmount')
      }
    },
    {
      name: 'salesQuantity',
      is_visible: true,
      type: 'quantity',
      get short_title() {
        return $t('IncomeStats.tableColumn_SalesQuantity')
      },
      get title() {
        return $t('IncomeStats.tableColumn_SalesQuantity')
      }
    },
    {
      name: 'salesAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('IncomeStats.tableColumn_SalesAmount')
      },
      get title() {
        return $t('IncomeStats.tableColumn_SalesAmount')
      }
    },
    {
      name: 'differenceQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_DifferenceQuantity')
      },
      get title() {
        return $t('IncomeStats.tableColumn_DifferenceQuantity')
      }
    },
    {
      name: 'differenceAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('IncomeStats.tableColumn_DifferenceAmount')
      },
      get title() {
        return $t('IncomeStats.tableColumn_DifferenceAmount')
      }
    },
    {
      name: 'differencePercentage',
      type: 'percentage',
      is_visible: true,
      get short_title() {
        return $t('IncomeStats.tableColumn_DifferencePercentage')
      },
      get title() {
        return $t('IncomeStats.tableColumn_DifferencePercentage')
      }
    }
  ]
}
