import BaseClass from '@/utils/baseclass'

export default class IncomeStats extends BaseClass {
  addItem(item) {
    let newItem = new SalesItem(item)
    this.list.push(newItem)
  }
}

class SalesItem {
  constructor(item) {
    this.recordType = item.recordType
    this.storeGroupName = item.storeGroupName
    this.salesItemGrossSalesPrice = item.salesItemGrossSalesPrice
    this.salesItemTaxRatio = item.salesItemTaxRatio
    this.salesItemNetSalesPrice = item.salesItemNetSalesPrice
    this.totalSalesQuantity = item.totalSalesQuantity
    this.totalGrossSalesAmount = item.totalGrossSalesAmount
    this.totalNetSalesAmount = item.totalNetSalesAmount
    this.totalDiscountedSalesAmount = item.totalDiscountedSalesAmount
    this.unitCost = item.unitCost
    this.totalCost = item.totalCost
    this.unitProfit = item.unitProfit
    this.totalProfit = item.totalProfit
    this.costPercentage = item.costPercentage
    this.profitPercentage = item.profitPercentage
    this.discountAmount = item.discountAmount
    this.name = item.name
    this.status = item.status
    this.stockItemId = item.stockItemId
    this.rowTextClass = item.menuItemIsActive ? this.getTextColor(item.recordType) : 'TableViewPassiveItems ' + this.getTextColor(item.recordType)
  }

  getTextColor(type) {
    let colorClass
    switch (type) {
      case 2:
        colorClass = 'c-success'
        break

      case 3:
        colorClass = 'c-warning'
        break

      default:
        colorClass = ''
        break
    }
    return colorClass
  }
}
