// import Reports from '../index'
import PurchaseReports from '../purchase.vue'
import ABCAnalysis from '../abc.vue'
import SalesReports from '../sales.vue'
import StockReports from '../stock.vue'
import IncomeStats from '../income-stats'
import ForecastSales from '../forecast-sales'

export default [

  {
    path: '/reports/purchase',
    name: 'PurchaseReports',
    component: PurchaseReports,
    meta: {
      permissionKey: 'ReportsMenu',
      page: 'reports'
    }
  },
  {
    path: '/reports/purchase/abc',
    name: 'ABCAnalysis',
    component: ABCAnalysis,
    meta: {
      permissionKey: 'ReportsMenu',
      page: 'reports'
    }
  },
  {
    path: '/reports/sales',
    name: 'SalesReports',
    component: SalesReports,
    meta: {
      permissionKey: 'ReportsMenu',
      page: 'reports'
    }
  },
  {
    path: '/reports/sales/income-stats',
    name: 'IncomeStats',
    component: IncomeStats,
    meta: {
      permissionKey: 'ReportsMenu',
      page: 'reports'
    }
  },
  {
    path: '/onhand/stock',
    name: 'StockReports',
    component: StockReports,
    meta: {
      permissionKey: 'StockOnHandMenu',
      page: 'stockonhand'
    }
  },
  {
    path: '/reports/sales/forecast/:startDate/:endDate',
    name: 'ForecastSales',
    component: ForecastSales,
    meta: {
      permissionKey: 'ReportsMenu',
      page: 'reports'
    }
  },
]
