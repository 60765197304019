export default function getFields($t) {
  return [
    {
      name: 'transactionTypeName',
      is_visible: true,
      get short_title() {
        return $t('StockReports.tableColumn_TransactionType_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_TransactionType')
      }
    },
    {
      name: 'totalQuantity',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_TransactionQuantity_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_TransactionQuantity')
      }
    },
    {
      name: 'totalAmount',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_TransactionAmount_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_TransactionAmount')
      }
    }
  ]
}
