export default function getFields($t) {
  return [
    {
      name: 'rank',
      is_visible: true,
      get short_title() {
        return $t('ABCAnalysis.tableColumn_Rank_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_Rank')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('ABCAnalysis.tableColumn_StockItemName_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_Name')
      }
    },
    {
      name: 'quantity',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('ABCAnalysis.tableColumn_Quantity_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_StockItemName')
      }
    },
    {
      name: 'baseUnitName',
      is_visible: true,
      get short_title() {
        return $t('ABCAnalysis.tableColumn_BaseUnitName_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_Quantity')
      }
    },
    {
      name: 'amount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('ABCAnalysis.tableColumn_Amount_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_BaseUnitName')
      }
    },
    {
      name: 'cumulativeAmount',
      is_visible: true,
      type: 'currency',
      get short_title() {
        return $t('ABCAnalysis.tableColumn_CumulativeAmount_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_Amount')
      }
    },
    {
      name: 'amountPercent',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('ABCAnalysis.tableColumn_AmountPercent_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_CumulativeAmount')
      }
    },
    {
      name: 'cumulativeAmountPercent',
      is_visible: true,
      type: 'percent',
      get short_title() {
        return $t('ABCAnalysis.tableColumn_CumulativeAmountPercent_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_AmountPercent')
      }
    },
    {
      name: 'category',
      headerPortal: 'headerCategory',
      is_visible: true,
      get short_title() {
        return $t('ABCAnalysis.tableColumn_Category_Short')
      },
      get title() {
        return $t('ABCAnalysis.tableColumn_Category_Short')
      }
    }
  ]
}
