import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import moment from 'moment'
import { getPaginationNumber } from '@/utils/pagination'

function getInitialSummary() {
  return {
    purchaseSummary: {
      previousMonthTotalPurchaseAmount: 0,
      currentMonthTotalPurchaseAmount: 0,
      differenceAmount: 0
    },
    profitSummary: {
      previousMonthTotalProfitAmount: 0,
      currentMonthTotalProfitAmount: 0,
      differenceAmount: 0
    },
    incomeSummary: {
      previousMonthTotalIncomeAmount: 0,
      currentMonthTotalIncomeAmount: 0,
      differenceAmount: 0
    },
    salesSummary: {
      previousMonthTotalSalesAmount: 0,
      currentMonthTotalSalesAmount: 0,
      differenceAmount: 0
    },
    productionSummary: {
      previousMonthTotalSalesAmount: 0,
      currentMonthTotalSalesAmount: 0,
      differenceAmount: 0
    }
  }
}

export default {
  namespaced: true,

  state: {
    Summary: getInitialSummary(),
    PurchaseReports: [],
    ProfitReports: [],
    ABCAnalysis: [],
    SalesReports: [],
    StockReports: [],
    WasteReports: [],
    ForecastSales: [],
    TransactionTypeList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    AbcPage: {
      number: 1,
      size: 20,
      total: 0
    },
    ForecastPage: {
      number: 1,
      size: 20,
      total: 0
    }
  },

  getters: {
    summary: state => state.Summary,

    TransactionTypeList: state => state.TransactionTypeList,

    purchaseSummary: state => state.Summary.purchaseSummary,

    purchaseDifferencePercentage: ({ Summary }) => {
      const {
        previousTotalPurchaseAmount,
        currentTotalPurchaseAmount,
        differenceAmount
      } = Summary.purchaseSummary
      if (previousTotalPurchaseAmount === 0) return null
      if (currentTotalPurchaseAmount === 0) return null
      return differenceAmount / previousTotalPurchaseAmount * 100
    },

    profitSummary: state => state.Summary.profitSummary,

    profitDifferencePercentage: ({ Summary }) => {
      const {
        previousTotalProfitAmount,
        currentTotalProfitAmount,
        differenceAmount
      } = Summary.profitSummary
      if (previousTotalProfitAmount === 0) return null
      if (currentTotalProfitAmount === 0) return null
      return differenceAmount / previousTotalProfitAmount * 100
    },
    incomeSummary: state => state.Summary.incomeSummary,

    incomeDifferencePercentage: ({ Summary }) => {
      const {
        previousTotalIncomeAmount,
        currentTotalIncomeAmount,
        differenceAmount
      } = Summary.incomeSummary
      if (previousTotalIncomeAmount === 0) return null
      if (currentTotalIncomeAmount === 0) return null
      return differenceAmount / previousTotalIncomeAmount * 100
    },

    salesSummary: state => state.Summary.salesSummary,

    salesDifferencePercentage: ({ Summary }) => {
      const {
        previousTotalSalesAmount,
        currentTotalSalesAmount,
        differenceAmount
      } = Summary.salesSummary
      if (previousTotalSalesAmount === 0) return null
      if (currentTotalSalesAmount === 0) return null
      return differenceAmount / previousTotalSalesAmount * 100
    },
    productionSummary: state => state.Summary.productionSummary,

    productionDifferencePercentage: ({ Summary }) => {
      const {
        previousTotalProductionAmount,
        currentTotalProductionAmount,
        differenceAmount
      } = Summary.productionSummary
      if (previousTotalProductionAmount === 0) return null
      if (currentTotalProductionAmount === 0) return null
      return differenceAmount / previousTotalProductionAmount * 100
    },

    purchaseReports: state => state.PurchaseReports,
    isPurchaseReportsEmpty: state => state.PurchaseReports.length === 0,

    wasteReports: state => state.WasteReports,
    isWasteReportsEmpty: state => state.WasteReports.length === 0,

    ABCAnalysis: state => state.ABCAnalysis,
    isABCAnalysisEmpty: state => state.ABCAnalysis.length === 0,

    salesReports: state => state.SalesReports,
    isSalesReportsEmpty: state => state.SalesReports.length === 0,

    stockReports: state => state.StockReports,
    isStockReportsEmpty: state => state.StockReports.length === 0,

    ForecastSales: state => state.ForecastSales,

    page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),

    abcPage: state => ({
      ...state.AbcPage,
      last: Math.ceil(state.AbcPage.total / state.AbcPage.size)
    }),

    ForecastPage: state => ({
      ...state.ForecastPage,
      last: Math.ceil(state.ForecastPage.total / state.ForecastPage.size)
    })
  },

  actions: {
    async getSummary({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getReportSummary',
        methodName: 'getReportSummary',
        method: () => {
          return HTTP.post('Report', payload)
        },
        success: result => {
          commit('UPDATE_SUMMARY', result.data)
          return result
        }
      })
      return results
    },

    async getForecastSales({ commit, state, dispatch }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.ForecastPage.number })
      const results = await requestHandler({
        title: 'getForecastSales',
        methodName: 'getForecastSales',
        method: () => {
          return HTTP.post('IncomeStats/Forecast', payload)
        },
        success: result => {
          commit('SET_FORECAST_SALES', result.data)
          return result
        }
      })
      return results
    },

    async getTransactionTypes({ commit }) {
      const results = await requestHandler({
        title: 'getTransactionTypes',
        methodName: 'getTransactionTypes',
        method: () => {
          return HTTP.get('StockItemTransaction/type/all')
        },
        success: result => {
          commit('SET_TRANSACTION_TYPE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getPurchaseReports({ commit, dispatch }, params) {
      const {
        groupType,
        storeList,
        includeSupplier,
        isSupplierPrimary,
        start,
        end
      } = params
      const results = await requestHandler({
        title: 'getPurchaseReports',
        methodName: 'getPurchaseReports',
        method: () => {
          const endpoint = 'Report/purchase'
          const params = {
            StartDate: start,
            StoreList: storeList.map(store => store.id),
            EndDate: end,
            IncludeSupplier: includeSupplier,
            IsSupplierPrimary: isSupplierPrimary,
            groupType: groupType || null
          }

          return HTTP.post(endpoint, params)
        },
        success: result => {
          commit('GET_PURCHASE_REPORTS', result.data.itemList)
          return result
        }
      })
      return results
    },

    async getWasteReports({ commit, dispatch }, params) {
      const {
        groupType,
        storeList,
        IncludeWasteGroup,
        IsWasteGroupPrimary,
        start,
        end
      } = params
      const results = await requestHandler({
        title: 'getWasteReports',
        methodName: 'getWasteReports',
        method: () => {
          const endpoint = 'Report/waste'
          const params = {
            StartDate: start,
            StoreList: storeList.map(store => store.id),
            EndDate: end,
            IncludeWasteGroup,
            IsWasteGroupPrimary,
            PageSize: 9999,
            PageNumber: 1,
            groupType
          }

          return HTTP.post(endpoint, params)
        },
        success: result => {
          commit('GET_WASTE_REPORTS', result.data.itemList)
          return result
        }
      })
      return results
    },

    async getABCAnalysis({ commit, dispatch, state }, { start, end, storeList, pageNumber, pageSize }) {
      const params = {
        StartDate: start,
        EndDate: end,
        StoreList: storeList.map(store => store.id),
        pageNumber: pageNumber === 'prev'
          ? state.AbcPage.number - 1
          : pageNumber === 'next'
            ? state.AbcPage.number + 1
            : pageNumber,
        pageSize: pageSize
      }

      const results = await requestHandler({
        title: 'getABCAnalysis',
        methodName: 'getABCAnalysis',
        method: () => {
          return HTTP.post(`Report/abcanalysis`, params)
        },
        success: result => {
          commit('GET_ABC_ANALYSIS', result.data)
          return result
        }
      })
      return results
    },

    async getSalesReports({ commit, dispatch }, { type, start, end, storeList }) {
      const endpoint = 'Report/sales'
      const params = {
        StoreList: storeList.map(s => s.id),
        ReportType: type,
        StartDate: start,
        EndDate: end
      }

      const results = await requestHandler({
        title: 'getSalesReports',
        methodName: 'getSalesReports',
        method: () => {
          return HTTP.post(endpoint, params)
        },
        success: result => {
          commit('GET_SALES_REPORTS', result.data.itemList)
          return result
        }
      })
      return results
    },

    async getStockReports({ commit, dispatch, state }, { typeUrlEndPoint = 'detailed', start, end, item, storeId, to = 1, pageSize, typeList }) {
      const endpoint = `Report/stockitemtransaction/${typeUrlEndPoint}`
      let PAGE_NUMBER

      switch (to) {
        case 'prev':
          PAGE_NUMBER = state.Page.number - 1
          break
        case 'next':
          PAGE_NUMBER = state.Page.number + 1
          break
        default:
          PAGE_NUMBER = to
          break
      }
      const params = [
        `StockItemId=${item.id}`,
        `StartDate=${start}`,
        `EndDate=${end}`,
        `StoreId=${storeId}`,
        `PageSize=${pageSize}`,
        `PageNumber=${PAGE_NUMBER}`,
        typeList.join('&')
      ]
      const url = `${endpoint}?${params.join('&')}`

      const results = await requestHandler({
        title: 'getStockReports',
        methodName: 'getStockReports',
        method: () => {
          return HTTP.get(url)
        },
        success: result => {
          commit('SET_STOCK_REPORTS', result.data.itemList)
          commit('SET_PAGE', result.data)
          return result
        }
      })
      return results
    },

    async resetStockReports({ commit }) {
      commit('SET_STOCK_REPORTS', [])
    },

    async downloadReports({ dispatch }, {url, params}) {
      const results = await requestHandler({
        title: 'downloadReports',
        methodName: 'downloadReports',
        method: () => HTTP.post(url, params),
        success: response => {
          let rowList = response.data.replace('sep=;', '').split(/\n/g)
          let content = []
          rowList.forEach(function(row) {
            if (row === '') return
            content.push(row.split(';'))
          })

          let csvContent = ''
          content.forEach(function(rowArray) {
            let row = rowArray.join(',')
            csvContent += row + '\r\n'
          })

          const encodedUri = 'data:text/csv;sep=;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent)
          return encodedUri
        }
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.Summary = getInitialSummary()
      state.ABCAnalysis = []
      state.StockReports = []
    },

    UPDATE_SUMMARY(state, summaryData) {
      state.Summary = summaryData
    },

    SET_FORECAST_SALES(state, data) {
      state.ForecastSales = data.itemList
      state.ForecastPage.number = data.pageNumber
      state.ForecastPage.size = data.pageSize
    },

    GET_PURCHASE_REPORTS(state, purchaseReports) {
      state.PurchaseReports = purchaseReports
    },

    GET_WASTE_REPORTS(state, wasteReports) {
      state.WasteReports = wasteReports
    },

    GET_ABC_ANALYSIS(state, data) {
      state.ABCAnalysis = data.itemList
      state.AbcPage.number = data.pageNumber
      state.AbcPage.size = data.pageSize
      state.AbcPage.total = data.totalCount
    },

    GET_SALES_REPORTS(state, salesReports) {
      state.SalesReports = salesReports
    },

    SET_STOCK_REPORTS(state, stockReports) {
      state.StockReports = stockReports
      state.StockReports.map(i => {
        i.modifiedOn = moment(i.modifiedOn).format('DD-MM-YYYY HH:mm')
      })
      
    },

    SET_PAGE(state, data) {
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    SET_TRANSACTION_TYPE_LIST(state, data) {
      state.TransactionTypeList = data.typeList
    }
  }
}
