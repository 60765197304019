export default function getFields($t, columns) {
  const supplierColumn = {
    name: 'supplierName',
    is_visible: true,
    style: 'width: 20%',
    have_tooltip: true,
    tooltip_name: 'storeGroups',
    get short_title() {
      return $t('PurchaseReports.tableColumn_SupplierName_Short')
    },
    get title() {
      return $t('PurchaseReports.tableColumn_SupplierName')
    }
  }

  const groupColumn = {
    name: 'groupName',
    is_visible: true,
    style: 'width: 20%',
    get short_title() {
      return columns.groupType.name
    },
    get title() {
      return columns.groupType.name
    }
  }

  const staticColumns = [{
    name: 'totalAmount',
    is_visible: true,
    type: 'currency',
    style: 'width: 20%',
    get short_title() {
      return $t('PurchaseReports.tableColumn_TotalAmount_Short')
    },
    get title() {
      return $t('PurchaseReports.tableColumn_TotalAmount_Short')
    }
  }, {
    name: 'spacer',
    is_visible: true,
    type: 'spacer'
  }]

  let fields = []

  if (columns.groupType) {
    fields.push(groupColumn)
  }

  if (columns.includeSupplier) {
    if (columns.isSupplierPrimary) {
      fields = [supplierColumn].concat(fields)
    } else {
      fields.push(supplierColumn)
    }
  }

  return fields.concat(staticColumns)
}
