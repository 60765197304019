import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import IncomeStats from '../model/income'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    IncomeStats: new IncomeStats(),
    Page: {
      number: 1,
      size: 25,
      total: 0
    },
    TotalInfo: {}
  },
  getters: {
    IncomeStats: state => state.IncomeStats.list,
    isEmpty: state => state.IncomeStats.length === 0,
    TotalInfo: state => state.TotalInfo,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },
  actions: {
    async getIncomeStats({ state, commit, dispatch }, params) {
      const loadingName = 'IncomeStats/getIncomeStats'
      const PageNumber = getPaginationNumber({ selectedPage: params.to, currentPage: state.Page.number })
      if (params.pageNumber === 1) dispatch('getIncomeStatsTotal', params)
      const infiniteScroll = params.infiniteScroll
      const payload = {
        ...params,
        pageNumber: PageNumber,
        pageSize: state.Page.size
      }
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('IncomeStats', payload)
        },
        success: result => {
          // data.infiniteScroll = infiniteScroll
          commit('UPDATE_INCOMESTATS_LIST', { ...result.data, infiniteScroll })
          return result
        }
      })
      return results
    },
    async getIncomeStatsTotal({ state, commit }, params) {
      const loadingName = 'IncomeStats/getIncomeStatsTotal'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('IncomeStats/total', params)
        },
        success: result => {
          commit('UPDATE_INCOMESTATS_PAGE', result.data)
          return result
        }
      })
      return results
    }
  },
  mutations: {
    RESET(state) {
      state.IncomeStats = new IncomeStats()
      state.Page.number = 1
      state.Page.total = 0
      state.TotalInfo = {}
    },
    UPDATE_INCOMESTATS_LIST(state, data) {
      data.infiniteScroll ? state.IncomeStats.mergeItems(data.itemList) : state.IncomeStats.addItems(data.itemList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
    },
    UPDATE_INCOMESTATS_PAGE(state, data) {
      state.TotalInfo = data.total
      state.Page.total = data.total.totalCount
    }
  }
}
