export default function getFields($t) {
  return [
    {
      name: 'transactionDate',
      is_visible: true,
      type: 'date',
      get short_title() {
        return $t('StockReports.tableColumn_TransactionDate_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_TransactionDate')
      }
    },
    {
      name: 'transactionTypeName',
      is_visible: true,
      get short_title() {
        return $t('StockReports.tableColumn_TransactionType_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_TransactionType')
      }
    },
    {
      name: 'transactionQuantity',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_TransactionQuantity_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_TransactionQuantity')
      }
    },
    {
      name: 'stockOnHandQuantity',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_StockOnHandQuantity_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_StockOnHandQuantity')
      }
    },
    {
      name: 'transactionCost',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_TransactionCost_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_TransactionCost')
      }
    },
    {
      name: 'transactionAmount',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_TransactionAmount_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_TransactionAmount')
      }
    },
    {
      name: 'cumulativeTransactionAmount',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_CumulativeTransactionAmount_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_CumulativeTransactionAmount')
      }
    },
    {
      name: 'averageCost',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_AverageCost_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_AverageCost')
      }
    },
    {
      name: 'stockOnHandAmount',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_StockOnHandAmount_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_StockOnHandAmount')
      }
    },
    {
      name: 'description',
      is_visible: true,
      get short_title() {
        return $t('StockReports.tableColumn_Description_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_Description')
      }
    },
    {
      name: 'documentNumber',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('StockReports.tableColumn_DocumentNumber_Short')
      },
      get title() {
        return $t('StockReports.tableColumn_DocumentNumber')
      }
    },
    {
      name: 'modifiedByFullName',
      is_visible: true,
      get short_title() {
        return $t('StockReports.tableColumn_modifiedBy')
      },
      get title() {
        return $t('StockReports.tableColumn_modifiedBy')
      }
    },
    {
      name: 'modifiedOn',
      is_visible: true,
      get short_title() {
        return $t('StockReports.tableColumn_modifiedOn')
      },
      get title() {
        return $t('StockReports.tableColumn_modifiedOn')
      }
    }
  ]
}
